
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentStyle {
  min-height: 100vh;
  overflow: auto;
}
.container-fluid {
  padding: 1em 2em;
}
.container {
  padding: 4em 12em;
}
.container-poperty-details {
  padding: 0em 8em 4em 8em;
}
.layout {
  width: 1200px;
}
.headerStyle {
  position: sticky;
  top: 0;
  z-Index: 1;
}

.logoStyle {
  height: 54px !important;
  border-radius: 0 !important;
}
.nearbyplacescarouselarrow{
  position: absolute;
  font-size: 20px;
  top: 40%;
  z-index: 1;
  color: '#2b2c32';
}
.left{
  left: 0;
}
.right{
  right: 0;
}
.nearbyplacescarousel{
  height: 180px;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.pin {
  cursor: 'pointer';
  position: relative;
  width: 60px;
  height: 60px;
  overflow: visible;
}

.pin img {
  position: relative;
  border: 4px solid #aa7e42;
  border-radius: 50%;
  top: -16px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  z-index: 1000;
}
.pin::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 23px solid transparent;
  border-right: 23px solid transparent;
  border-top: 26px solid #aa7e42;
  z-index: 999;
}
@media only screen and (max-width: 576px) {
  .logoStyle {
    height: 44px !important;
  }
  .nearbyplacescarousel{
    height: 120px;
    overflow: hidden;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .container {
    padding: 2em 2em;
  }
  .container-poperty-details {
    padding: 0 2em 2em 2em;
  }
}

.siderStyle {
  position: sticky;
  top: 0;
  z-Index: 1;
  text-align: center;
}

.footerStyle {
  width: 100%;
  display: grid;
  align-content: center;
  text-align: center;
  max-height: 50px;
}

.layoutStyle {
  overflow: hidden;
}

.sigCanvas {
  width: 100%;
  height: 240;
  cursor: crosshair;
}

/* Custom styles for Carousel dots */
.ant-carousel .slick-dots {
  bottom: 20px; /* Adjust the position of the dots */
}

.ant-carousel .slick-dots li {
  margin: 0 2px; /* Adjust the space between dots */
}

.ant-carousel .slick-dots li button {
  height: 5px; /* Adjust the height of the dot */
  background-color: #fbfbfb; /* Change the background color of the dot */
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #aa7e42; /* Change the active dot color */
}

.icon-style {
  font-size: 40px;
  margin-right: 6px;
}
.icon-style-dg {
  font-size: 18px;
  margin-right: 6px;
}
.tag-icon-style {
  margin-right: 6px;
}

.custom-anchor {
  background-color: #fff;
  padding: 8px 0 0 0;
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 8px; /* or your preferred spacing between buttons */
  padding: 10px 0;
  scrollbar-width: none; /* for Firefox */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.custom-switch {
  position: fixed;
  bottom: 50px;
}

.custom-switch span {
  padding-inline-start: 10px !important;
  padding-inline-end: 10px !important;
}

.ant-switch {
  background-color: #2b2c32 !important;
  border: none;
}

.ant-switch-checked {
  background-color: #aa7e42 !important;
}

.custom-tag {
  font-size: 14px;
}

.customCheckboxButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid #ebdecd;
  border-radius: 12px;
  text-align: center;
  transition: all 0.3s;
  background-color: #fbfbfb;
  color: #2b2c32;
  width: 100px;
  margin: 10px 0;
}
.customCheckboxButton:hover {
  cursor: pointer;
  border-color: #ebdecd;
}
/* Specific class for when the checkbox is checked */
.customCheckboxChecked {
  background-color: #aa7e42;
  color: white;
  border-color: #ebdecd;
}
.customCheckboxContainer .ant-checkbox-inner {
  display: none;
}
.checkboxicon {
  margin-top: 8px;
  font-size: 24px;
}

.customRadioGroup {
  display: flex;
  flex-wrap: wrap;
}
.customRadioButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid #ebdecd;
  border-radius: 12px;
  text-align: center;
  transition: all 0.3s;
  background-color: #fbfbfb;
  color: #2b2c32;
  width: 160px;
  margin: 6px 0;
}
.customRadioButton:hover {
  cursor: pointer;
  background-color: #aa7e42;
  border-color: #ebdecd;
}

.customRadioChecked {
  background-color: #aa7e42;
  color: white;
  border-color: #ebdecd;
}

.customRadioContainer .ant-radio {
  display: none;
}
.Radioicon {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 24px;
}

.ant-image .ant-image-img {
  border-radius: 12px;
}

.Hosticon {
  color: #2b2c32;
  font-size: 36px;
}

.Dashicon {
  color: #aa7e42;
  font-size: 18px;
}

.Anchoricon {
  font-size: 24px;
  margin-bottom: 4px;
}

.dgImg {
  border: solid 8px #fbfbfb;
  position: relative;
  z-index: 1;
  top: 50px;
}

.fixed-bottom-card {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: white; /* To ensure the background is opaque */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
  border-radius: 0;
}
@media (min-width: 768px) {
  .host-card-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .host-card {
    display: none;
  }
}